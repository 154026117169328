import { createPortal } from 'react-dom';
import { Dialog } from '@lunit/oncology-components';
import type { PromptContextProps } from './Prompt.types';

const Prompt = ({ open, handlePrompt, promptContent }: PromptContextProps) => {
  if (!open || !promptContent) {
    return null;
  }
  const {
    icon,
    title,
    content,
    closable,
    cancelText,
    submitText,
    useCustomAction,
    disableBackdropClick,
  } = promptContent;

  return (
    open &&
    createPortal(
      <Dialog
        open
        onClose={(_, reason) => {
          if (reason !== 'backdropClick') {
            handlePrompt(null);
          } else if (!disableBackdropClick) handlePrompt(null);
        }}
        title={title}
        icon={icon}
        text={typeof content === 'string' ? content : undefined}
        cancelText={cancelText}
        submitText={submitText}
        dialogAction={useCustomAction}
        closable={closable}
        isPrompt
      >
        {typeof content === 'object' && content}
      </Dialog>,
      document.body,
    )
  );
};

export default Prompt;
