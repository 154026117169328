import React from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { ResizeObserverProvider, ScopeComponentsProvider } from '@lunit/oncology-components';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import env from './env';
import { PromptProvider } from './components/common/Prompt';

Sentry.init({
  dsn: env.SENTRY_CONFIG_URL,
  integrations: [new BrowserTracing()],
  environment: env.isProduction ? 'production' : 'development',
  tracesSampleRate: env.isProduction ? 0.2 : 1.0,
  release: process.env.REACT_APP_COMPONENT_VERSION,
});

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <React.StrictMode>
    <ScopeComponentsProvider>
      <ResizeObserverProvider>
        <PromptProvider>
          <App />
        </PromptProvider>
      </ResizeObserverProvider>
    </ScopeComponentsProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
