import { createContext, ReactNode, useMemo } from 'react';
import Prompt from './Prompt';
import usePrompt from './usePrompt';
import type { PromptContextProps } from './Prompt.types';

const PromptContext = createContext<PromptContextProps>({} as PromptContextProps);

const PromptProvider = ({ children }: { children: ReactNode }) => {
  const { open, handlePrompt, promptContent } = usePrompt();
  const value = useMemo(() => {
    return { open, handlePrompt, promptContent };
  }, [open, handlePrompt, promptContent]);

  return (
    <PromptContext.Provider value={value}>
      <>
        <Prompt
          open={open}
          handlePrompt={handlePrompt}
          promptContent={promptContent}
        />
        {children}
      </>
    </PromptContext.Provider>
  );
};

export { PromptContext, PromptProvider };
