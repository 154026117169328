import { forwardRef } from 'react';
import { StyledRadioGroup } from './RadioGroup.styled';
import type { RadioGroupProps } from './RadioGroup.types';

const RadioGroup = forwardRef<HTMLDivElement, RadioGroupProps>(
  ({ defaultValue, name, row, children, onChange, value, ...props }: RadioGroupProps, ref) => {
    return (
      <StyledRadioGroup
        {...props}
        defaultValue={defaultValue}
        name={name}
        onChange={onChange}
        value={value}
        row={row}
        ref={ref}
      >
        {children}
      </StyledRadioGroup>
    );
  },
);

export default RadioGroup;
