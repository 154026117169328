import { styled, Box, Radio } from '@mui/material';

export const Container = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  gap: theme.spacing(2),
}));

export const StyledRadio = styled(Radio)(({ theme }) => ({
  width: '22px',
  height: '22px',
  padding: 0,
  borderRadius: '50%',
  '&.Mui-disabled, &.Mui-disabled + .MuiTypography-root': {
    opacity: 0.4,
  },
  '&.Mui-focusVisible > div': {
    boxShadow: `0 0 0 1px ${theme.palette.lunitTeal[10]}`,
  },
}));

export const UncheckedIcon = styled('div')(({ theme }) => ({
  width: '18px',
  height: '18px',
  border: `1.5px solid ${theme.palette.neutralGrey[45]}`,
  borderRadius: '50%',
  boxSizing: 'border-box',
}));

export const CheckedIcon = styled('div')(({ theme }) => ({
  width: '18px',
  height: '18px',
  border: `4px solid #768EF2`,
  borderRadius: '50%',
  boxSizing: 'border-box',
}));
