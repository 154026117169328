import { Typography } from '@mui/material';
import clsx from 'clsx';
import { forwardRef } from 'react';
import { Container, StyledRadio, UncheckedIcon, CheckedIcon } from './Radio.styled';
import type { RadioProps } from './Radio.types';

const Radio = forwardRef<HTMLSpanElement, RadioProps>(
  (
    {
      value,
      name,
      checked,
      defaultChecked,
      disabled,
      required,
      onChange,
      onChangeCapture,
      inputProps,
      inputRef,
      label,
      ...rootProps
    }: RadioProps,
    ref,
  ) => {
    return (
      <Container
        {...rootProps}
        className={clsx('radio', rootProps.className)}
        component={label != null ? 'label' : 'span'}
        ref={ref}
      >
        <StyledRadio
          disableRipple
          icon={<UncheckedIcon />}
          checkedIcon={<CheckedIcon />}
          value={value}
          name={name}
          checked={checked}
          defaultChecked={defaultChecked}
          disabled={disabled}
          required={required}
          onChange={onChange}
          onChangeCapture={onChangeCapture}
          inputProps={inputProps}
          inputRef={inputRef}
        />
        {label && (
          <Typography
            variant="body_m1"
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {label}
          </Typography>
        )}
      </Container>
    );
  },
);

export default Radio;
