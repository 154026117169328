import qs from 'qs';
import wretch, { WretchResponseChain, WretchError } from 'wretch';
import { camelize, decamelize } from '@ridi/object-case-converter';
import env, { API_TARGETS, DEFAULT_API_TARGET } from '../../env/index';
import accessTokenManager from './accessTokenManager';
import { logout } from '../../pages/auth/utils';

wretch().errorType('json');

export enum ErrorMessage {
  AuthError = 'AUTH_ERROR',
  BadRequest = 'BAD_REQUEST',
  ServerError = 'SERVER_ERROR',
  NotFoundError = 'NOT_FOUND',
  TimeoutError = 'Request timed out',
  FetchError = 'Failed to fetch',
}

export enum FetchMethod {
  Get = 'get',
  Post = 'post',
  Put = 'put',
  Delete = 'delete',
  Patch = 'patch',
}

interface RequestParamType {
  method?: FetchMethod;
  path: string;
  getText?: boolean;
  skipJson?: boolean;
  skipAuth?: boolean; // Can only be skipped on getting JWT token
  payload?: unknown;
  urlBase?: 'API_URLS' | 'API_CARROT_URLS';
}

export class APIError extends Error {
  status: number;

  details: string = '';

  timestamp: string = '';

  code: number = 0;

  constructor(type: ErrorMessage, error: WretchError) {
    super(type);
    this.status = error.status;
    if (error.json) {
      this.details = error.json.details;
      this.timestamp = error.json.timestamp;
      this.code = error.json.code;
    }
  }
}

let currentTarget = DEFAULT_API_TARGET;
export const setAPITarget = (target: API_TARGETS) => {
  currentTarget = target;
};

const errorHandlingWrapper = (
  wrapper: WretchResponseChain<any>,
  options: {
    skipJson: boolean | undefined;
    getText: boolean | undefined;
    skipAuth: boolean | undefined;
  },
) =>
  wrapper
    .badRequest((error: WretchError) => {
      throw new APIError(ErrorMessage.BadRequest, error);
    })
    .notFound((error: WretchError) => {
      throw new APIError(ErrorMessage.NotFoundError, error);
    })
    .unauthorized(async (error: WretchError) => {
      if (options.skipAuth) {
        throw new APIError(ErrorMessage.AuthError, error);
      }
      if (!error.json?.code) {
        logout();
      }
      throw new APIError(ErrorMessage.AuthError, error);
    })
    .forbidden((error: WretchError) => {
      if (!error.json?.code) {
        logout();
      }
      throw new APIError(ErrorMessage.AuthError, error);
    })
    .internalError((error: WretchError) => {
      throw new APIError(ErrorMessage.ServerError, error);
    })
    .timeout((error: WretchError) => {
      throw new APIError(ErrorMessage.TimeoutError, error);
    })
    .fetchError((error: WretchError) => {
      throw new APIError(ErrorMessage.FetchError, error);
    });

export const request = <Response = any>({
  method = FetchMethod.Get,
  path,
  getText,
  skipJson,
  skipAuth,
  payload,
  urlBase = 'API_URLS',
}: RequestParamType): Promise<Response> => {
  if (!skipAuth && !accessTokenManager.hasAccessTokenInStorage()) {
    throw new Error(ErrorMessage.AuthError);
  }
  const wrapper = errorHandlingWrapper(
    wretch(`${env[urlBase][currentTarget]}/${path}`)
      .errorType('json')
      .auth(skipAuth ? '' : `Bearer ${accessTokenManager.getAccessToken()}`)
      .json(
        urlBase === 'API_CARROT_URLS'
          ? (payload as object)
          : decamelize(payload, { recursive: true }),
      )
      [method](),
    { skipJson, getText, skipAuth },
  );
  if (getText) {
    return wrapper.text();
  }
  if (skipJson) {
    return wrapper.res((res: any) => res.statusText);
  }
  return wrapper.json((res: any) =>
    camelize(res, { recursive: true, excludes: ['presigned_url_info'] }),
  );
};

export const DEFAULT_TOKEN_EXP_SEC = 259200;

const qsStringfyDefaultOptions = {
  encode: true,
  indices: false,
};

export const getFullPath = (path: string) => `${env.API_URLS[currentTarget]}/research${path}`;

export const getSlidesFullPath = (path: string) =>
  `${env.API_SLIDE_URLS[currentTarget]}/research${path}`;

export const fetchGet = (path: string) =>
  fetch(getFullPath(path), {
    headers: new Headers({
      Authorization: `Bearer ${accessTokenManager.getAccessToken()}`,
      accept: 'application/json',
    }),
  });

export const fetchGetSlides = (path: string) =>
  fetch(getSlidesFullPath(path), {
    headers: new Headers({
      Authorization: `Bearer ${accessTokenManager.getAccessToken()}`,
      accept: 'application/json',
    }),
  });

export const queryString = (obj: any) => qs.stringify(obj, qsStringfyDefaultOptions);
