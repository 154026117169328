import { useRecoilValue } from 'recoil';
import { useLocation } from 'react-router-dom';
import { Box, Menu, MenuItem } from '@mui/material';
import { EllipsisTypography } from '@lunit/oncology-components';
import Skeleton from 'react-loading-skeleton';
import { Projects } from 'src/api/project/models';
import { isVisCompleteAtom } from 'src/state';
import { generateProjectLink } from 'src/utils/projects';

interface AppListMenuProp {
  isOpen: boolean;
  isLoading: boolean;
  anchorEl: HTMLElement | null;
  projects: Projects['projects'];
  customerCode: string;
  onClose: () => void;
}

function AppSkeleton() {
  return (
    <Skeleton
      width={184}
      height={32}
      style={{ marginLeft: '8px' }}
    />
  );
}

function AppListMenu({
  isOpen,
  isLoading,
  anchorEl,
  onClose,
  projects,
  customerCode,
}: AppListMenuProp) {
  const location = useLocation();
  const disableGetProject = location.pathname.includes('/visualizer');
  const isVisComplete = useRecoilValue(isVisCompleteAtom);
  return (
    <Menu
      open={isOpen}
      anchorEl={anchorEl}
      onClose={onClose}
      PaperProps={{
        elevation: 1,
        sx: {
          width: '200px',
          borderRadius: '8px',
        },
      }}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
    >
      {isLoading ? (
        <Box>
          <AppSkeleton />
          <AppSkeleton />
          <AppSkeleton />
          <AppSkeleton />
        </Box>
      ) : (
        projects.map((project) => (
          <MenuItem
            key={project.id}
            sx={{ color: 'inherit' }}
            disabled={disableGetProject && !isVisComplete}
            onClick={() => {
              window.location.replace(generateProjectLink(project, customerCode));
            }}
          >
            <EllipsisTypography
              variant="body5"
              direction="row"
            >
              {project.title}
            </EllipsisTypography>
          </MenuItem>
        ))
      )}
    </Menu>
  );
}

export default AppListMenu;
