import { useQuery } from 'react-query';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { ReadingRecord } from 'src/api/slide/models';
import { getAllReadingRecords, getSlides } from 'src/api/slide/queries';
import { APIError } from 'src/api/utils/apiRequestHelper';
import { readingRecordsAtom } from 'src/state/readingRecords';
import { slidesAtom } from 'src/state/slides';

const useSlides = (params: URLSearchParams, enabled: boolean) => {
  const setSlides = useSetRecoilState(slidesAtom);

  const initialData = useRecoilValue(slidesAtom);

  const data = useQuery(
    getSlides.key(),
    () => getSlides(params.get('group') || '', params.get('customer_code') || ''),
    {
      suspense: false,
      cacheTime: 3600 * 1000,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      initialData,
      enabled,
      onSuccess: (slidesData) => {
        setSlides(slidesData);
      },
    },
  );

  return data;
};

const useReadingRecords = (params: URLSearchParams, enabled: boolean) => {
  const setReadingRecords = useSetRecoilState(readingRecordsAtom);

  const readingRecords = useQuery<unknown, APIError, { data: ReadingRecord[], listError: boolean, isListError: boolean }>(
    getAllReadingRecords.key(params.get('group')!, params.get('customer_code')!),
    () => {
      return getAllReadingRecords(params.get('group') || '', params.get('customer_code') || '');
    },
    {
      suspense: false,
      cacheTime: 3600 * 1000,
      refetchOnWindowFocus: false,
      useErrorBoundary: false,
      enabled,
      onSuccess: (readingRecords: { data: ReadingRecord[] }) => {
        setReadingRecords(readingRecords);
      },
    },
  );

  return readingRecords;
};

// eslint-disable-next-line import/prefer-default-export
export { useSlides, useReadingRecords };
