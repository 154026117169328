import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Typography } from '@mui/material';
import { isCommentLoadingAtom, slideInfoAtom } from 'src/state/slides';
import { isLoadingDataAtom, zoomAtom } from 'src/state';
import type { ReadingRecord, SlideDetails } from 'src/api/slide/models';
import withErrorBoundary from './withErrorBoundary';
import { TaskClasses, TaskClass } from '../../types';
import { Label, Toggle, ToggleWrapper, Wrapper } from './styled';
import { Control, getShownClasses, isaClass, PanelSkeleton } from './const';
import HeatmapSubOption from './HeatmapOption';
import Comment from './Comment';
import Score from './Score';
import ResearchTimer from './ResearchTimer/ResearchTimer';
import { timerCommentScoreDisabledAtom } from 'src/state/timer';

interface PanelSelectorProps {
  isError: boolean;
  active: boolean;
  classes: string[];
  cellClasses: [];
  taskClasses: TaskClasses;
  tissueClasses: [];
  structureClasses: [];
  currentReadingRecord?: ReadingRecord;
  slideName: string | null;
  currentSlide: SlideDetails;
  onChange: (values: string[]) => void;
  onChangeActive: (values: boolean) => void;
}

function PanelSelector({
  isError,
  active,
  classes,
  cellClasses,
  taskClasses,
  tissueClasses,
  structureClasses,
  slideName,
  currentReadingRecord,
  currentSlide,
  onChange,
  onChangeActive,
}: PanelSelectorProps) {
  const zoomData = useRecoilValue(zoomAtom);
  const isCommentLoading = useRecoilValue(isCommentLoadingAtom);
  const slideInfo = useRecoilValue(slideInfoAtom);
  const isScoreAndCommentDisabledByTimer = useRecoilValue(timerCommentScoreDisabledAtom);
  const isLoadingData = useRecoilValue(isLoadingDataAtom);
  const prevSlideInfoRef = useRef(slideInfo);
  const [shownClasses, setShownClasses] = useState<any>(
    getShownClasses({
      tissueClasses,
      structureClasses,
      cellClasses,
      taskClasses,
    }),
  );
  const [selectedScore, setSelectedScore] = useState<string>('');

  const onChangeCheckbox = (id: string) => {
    const values = [id];
    return (event: any) => {
      const prevClasses = classes;
      let newClasses = prevClasses.slice();
      values.forEach((value) => {
        if (event.target.checked && !newClasses.includes(value)) {
          newClasses = newClasses.concat(value);
        } else if (!event.target.checked && newClasses.includes(value)) {
          newClasses = newClasses.filter((c) => c !== value);
        }
      });
      onChange(newClasses);
    };
  };

  const onKeyDown = useCallback(
    (e: any) => {
      if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
        return;
      }
      if (e.code === 'KeyO' && !(e.shiftKey || e.altKey || e.ctrlKey || e.metaKey)) {
        onChangeActive(!active);
        return;
      }
      if (Number.isNaN(+e.key)) return;
      const idx = +e.key === 0 ? 9 : +e.key - 1;
      if (idx < 0) return;
      const targetClassId = shownClasses[idx] && shownClasses[idx].id;
      if (!targetClassId) return;
      if (!classes.includes(targetClassId)) {
        onChange(classes.concat(targetClassId));
      } else {
        onChange(classes.filter((id) => id !== targetClassId));
      }
    },
    [active, classes, onChange, onChangeActive, shownClasses],
  );

  useEffect(() => {
    document.addEventListener('keydown', onKeyDown);
    return () => {
      document.removeEventListener('keydown', onKeyDown);
    };
  }, [onKeyDown]);

  useEffect(() => {
    setShownClasses(
      getShownClasses({
        tissueClasses,
        structureClasses,
        cellClasses,
        taskClasses,
      }),
    );
  }, [cellClasses, structureClasses, taskClasses, tissueClasses]);

  useEffect(() => {
    if (slideInfo !== prevSlideInfoRef.current) {
      setSelectedScore(currentReadingRecord?.score!);
      prevSlideInfoRef.current = slideInfo;
    }
  }, [currentReadingRecord, slideInfo]);

  const onChangeActiveHandler = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLInputElement;
    onChangeActive(target.checked);
  };

  const onChangeScoreHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedScore(event.target.value);
  };

  const isScoreAndCommentDisabled = !!currentReadingRecord || isScoreAndCommentDisabledByTimer;

  return (
    <Wrapper>
      <Score
        selectedScore={selectedScore}
        recordScore={currentReadingRecord?.score!}
        onChangeScoreHandler={onChangeScoreHandler}
        disabled={isScoreAndCommentDisabled}
      />
      <Comment
        isLoading={isCommentLoading}
        recordScore={currentReadingRecord?.score!}
        recordComment={currentReadingRecord?.comment!}
        disabled={isScoreAndCommentDisabled}
      />
      <ResearchTimer
        selectedScore={selectedScore}
        currentReadingRecord={currentReadingRecord}
        slideName={slideName}
        currentSlide={currentSlide}
      />
      {shownClasses.length > 0 && (
        <>
          <Typography sx={{ margin: '12px 0' }}>Histology Panel</Typography>
          <ToggleWrapper>
            <input
              type="checkbox"
              id="view-mode-toggle"
              onChange={onChangeActiveHandler}
              checked={active}
            />
            <Label htmlFor="view-mode-toggle">
              <Toggle />
              <span>View Mode</span>
            </Label>
          </ToggleWrapper>
          {!isError && isLoadingData && <PanelSkeleton />}
          {shownClasses.map((taskClass: TaskClass) => (
            <div key={taskClass.id}>
              <Control
                id={taskClass.id}
                checked={classes.includes(taskClass.id)}
                onChange={onChangeCheckbox(taskClass.id)}
                color={taskClass.color || 'grey'}
                invisible={!active || (isaClass(cellClasses, taskClass) && zoomData.zoom < 10)}
              >
                {taskClass.id}
              </Control>
              {taskClass.id === 'AI-P' && (
                <HeatmapSubOption
                  isInTooltip={false}
                  hasCenter={false}
                />
              )}
            </div>
          ))}
        </>
      )}
    </Wrapper>
  );
}

export default withErrorBoundary(PanelSelector);
