import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import type { ReadingRecord } from 'src/api/slide/models';
import { readingRecordsAtom } from 'src/state/readingRecords';

interface Params {
  groupId: string | null;
  slideName: string | null;
  customerCode: string | null;
}

const useCurrentReadingRecord = (params: Params) => {
  const readingRecords = useRecoilValue(readingRecordsAtom);

  function getThisReadingRecord(readingRecordParams: Params, records: ReadingRecord[] | undefined) {
    if (!records || !records.length) return undefined;

    return records?.find(
      (record) =>
        record.projectName === readingRecordParams.groupId &&
        record.slideName === readingRecordParams.slideName &&
        record.customer === readingRecordParams.customerCode,
    );
  }

  const currentReadingRecord = useMemo(
    () => getThisReadingRecord(params, readingRecords.data ?? []) ?? undefined,
    [params, readingRecords.data],
  );

  return {
    readingRecords: readingRecords.data,
    currentReadingRecord,
  };
};
export default useCurrentReadingRecord;
