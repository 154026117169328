import { useTheme } from '@mui/material';
import { ScalebarLocation } from '@lunit/osd-react-renderer';
import { useCallback, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { SlideDetails } from 'src/api/slide/models';
import { getSlidesFullPath } from 'src/api/utils/apiRequestHelper';
import { Origin } from 'src/hooks/visualizer/webgl/types';
import useVisualization from 'src/hooks/visualizer/useVisualization';
import ReadOnlyViewer from './ReadOnlyViewer';
import { SlideData, TaskClasses } from '../../types';

const MICRONS_PER_METER = 1000000;

interface SlideViewerProps {
  histologyActive: boolean;
  isVisComplete: boolean;
  enabledClasses: string[];
  currentSlide: SlideDetails;
  taskClasses: TaskClasses;
  slideData: SlideData;
  handleVisComplete: (state: boolean) => void;
  handleStartTimerPrompt: (slide: SlideDetails) => void;
}

function SlideViewer({
  histologyActive,
  isVisComplete,
  enabledClasses,
  currentSlide,
  taskClasses,
  slideData,
  handleVisComplete,
  handleStartTimerPrompt,
}: SlideViewerProps) {
  const theme = useTheme();
  const canvasOverlayRef = useRef<any>(null);
  const location = useLocation();

  const { prepare, drawWithCanvas, drawWithWebGL, rerender, destroy, canPrepareVisualization } =
    useVisualization(slideData, taskClasses);

  useEffect(() => {
    destroy();
  }, [destroy, location.search]);

  useEffect(() => {
    if (canPrepareVisualization) {
      prepare()
        .catch((error: any) => {
          // eslint-disable-next-line no-alert
          window.alert(`Failed to prepare AI result visualization : ${error}`);
        })
        .finally(() => {
          handleVisComplete(true);
          handleStartTimerPrompt(currentSlide);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canPrepareVisualization]);

  useEffect(() => {
    if (isVisComplete) rerender(enabledClasses, canvasOverlayRef);
  }, [enabledClasses, canvasOverlayRef, rerender, isVisComplete]);

  const onCanvasOverlayRedraw = useCallback(
    (overlayCanvasEl: HTMLCanvasElement, viewer: OpenSeadragon.Viewer) => {
      const context = overlayCanvasEl.getContext('2d');
      if (context && histologyActive) drawWithCanvas(context, viewer, enabledClasses);
    },
    [drawWithCanvas, enabledClasses, histologyActive],
  );

  const onWebGLOverlayRedraw = useCallback(
    (
      glCanvas: HTMLCanvasElement,
      normalCanvas: HTMLCanvasElement,
      viewer: OpenSeadragon.Viewer,
      origin: Origin,
    ) => {
      const glCtx = glCanvas.getContext('webgl2', {
        antialias: true,
        premultipliedAlpha: false,
      });
      const ctx = normalCanvas.getContext('2d');

      if (!glCtx || !ctx) return;

      if (histologyActive && enabledClasses) {
        drawWithWebGL(ctx, glCtx, viewer, origin, enabledClasses);
      }
    },
    [enabledClasses, histologyActive, drawWithWebGL],
  );

  return (
    /* eslint-disable react/no-unknown-property */
    <ReadOnlyViewer
      slideData={{
        slideDziMetaUrl: getSlidesFullPath(
          `/slides/images/dzi/${encodeURIComponent(currentSlide?.slideUrl)}`,
        ),
      }}
      mpp={slideData?.currentSlide.mpp}
      canvasOverlay={
        <canvasOverlay
          ref={canvasOverlayRef}
          onRedraw={onCanvasOverlayRedraw}
        />
      }
      webGLOverlay={<webGLOverlay onRedraw={onWebGLOverlayRedraw} />}
      scaleBar={
        <scalebar
          pixelsPerMeter={MICRONS_PER_METER / (slideData?.currentSlide.mpp || 0.25)}
          xOffset={10}
          yOffset={30}
          barThickness={2}
          color={theme.palette.darkGrey[80]}
          fontColor={theme.palette.darkGrey[80]}
          backgroundColor="rgba(255,255,255,0.5)"
          location={ScalebarLocation.BOTTOM_RIGHT}
          stayInsideImage={false}
        />
      }
    />
    /* eslint-enable react/no-unknown-property */
  );
}

export default SlideViewer;
