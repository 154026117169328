import MuiToggleButton from '@mui/material/ToggleButton';
import isIconToggleButton from './ToggleButton.utils';

import type { ToggleButtonProps } from './ToggleButton.types';

function makePadding(size: ToggleButtonProps['size']): string {
  if (size === 'small') {
    return '4px 8px';
  }
  return '8px 12px';
}

function ToggleButton(props: ToggleButtonProps) {
  if (isIconToggleButton(props)) {
    const { icon, ...otherProps } = props;
    return <MuiToggleButton {...otherProps}>{icon}</MuiToggleButton>;
  }

  const { icon, label, size, ...otherProps } = props;
  return (
    <MuiToggleButton
      size={size}
      sx={{ padding: makePadding(size) }}
      color={otherProps.color}
      {...otherProps}
    >
      {icon}
      {label}
    </MuiToggleButton>
  );
}
export default ToggleButton;
