import { useState, useMemo, useEffect } from 'react';
import { CloseSmall, ArrowLeft, ArrowRight } from '@lunit/oncology-icons';

import { StyledDatePicker } from './DatePicker.styled';
import type { DatePickerProps } from './DatePicker.types';
import { Button } from '../Button';

import theme from '../../theme';

function Dialog({ alignment, placeholder, onChange, value, ...props }: DatePickerProps) {
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const { slots, slotProps, ...otherProps } = props;

  const handleFieldClick = () => {
    setOpen(true);
  };

  const handleChange = (date: any, context: any) => {
    setSelectedDate(date);
    if (onChange) onChange(date, context);
    setOpen(false);
  };

  const handleFieldClearClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    handleChange(null, null);
  };

  const popperPlacement = useMemo(() => {
    switch (alignment) {
      case 'left':
        return 'bottom-start';
      case 'right':
        return 'bottom-end';
      default:
        return 'bottom';
    }
  }, [alignment]);

  useEffect(() => {
    if (value === null && selectedDate !== null) {
      setSelectedDate(null);
    }
    if (value !== null && selectedDate === null) {
      setSelectedDate(value);
    }
  }, [value, selectedDate, setSelectedDate]);

  const canUseToday = useMemo(() => {
    const today = new Date();
    if (props.minDate && today < props.minDate) return false;
    if (props.maxDate && today > props.maxDate) return false;
    return true;
  }, [props.minDate, props.maxDate]);

  return (
    <StyledDatePicker
      open={open}
      value={selectedDate}
      onChange={handleChange}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      views={['day', 'month', 'year']}
      slots={{
        inputAdornment: () => {
          if (selectedDate) {
            return (
              <Button
                variant="ghost"
                color="secondary"
                size="small"
                icon={<CloseSmall />}
                sx={{
                  color: theme.palette.neutralGrey[0],
                  borderColor: 'transparent !important',
                }}
                onClick={handleFieldClearClick}
              />
            );
          }
          return null;
        },
        leftArrowIcon: ArrowLeft,
        rightArrowIcon: ArrowRight,
        ...(slots as any),
      }}
      slotProps={{
        textField: {
          variant: 'outlined',
          onClick: handleFieldClick,
          InputProps: {
            placeholder: placeholder || 'mm/dd/yyyy',
            sx: {
              borderRadius: '8px',
              // According to designer, the width of the date picker should be enforced at 320px
              // when the placement is bottom. that is why we have !important here.
              width: popperPlacement === 'bottom' ? '320px !important' : 'auto',
              borderColor: 'red',
              ...theme.typography.body5,

              '& input': {
                textTransform: 'lowercase',
              },
              '& ::placeholder': {
                textTransform: 'none',
              },
            },
            onMouseDown: (e: React.MouseEvent<HTMLInputElement>) => {
              const element = e.target as HTMLElement;
              if (element.tagName === 'INPUT') {
                e.preventDefault();
              }
            },
          },
        },
        popper: {
          placement: popperPlacement,
          modifiers: [
            {
              name: 'offset',
              enabled: true,
              options: {
                offset: [0, 8],
              },
            },
          ],
          sx: {
            '& .MuiPickersDay-today': {
              border: `1px solid ${theme.palette.neutralGrey[0]} !important`,
              borderRadius: '50%',
            },

            '& .MuiPickersDay-root:hover': {
              border: `1px solid ${theme.palette.neutralGrey[0]}`,
              borderRadius: '50%',
            },

            '& .MuiPickersDay-root.Mui-selected': {
              backgroundColor: `${theme.palette.lunitTeal[40]} !important`,
              color: `${theme.palette.neutralGrey[97]} !important`,
            },
          },
        },
        desktopPaper: {
          sx: {
            background: theme.palette.neutralGrey[70],
          },
        },
        previousIconButton: {
          sx: {
            color: theme.palette.neutralGrey[0],
          },
        },
        nextIconButton: {
          sx: {
            color: theme.palette.neutralGrey[0],
          },
        },
        actionBar: {
          actions: ['today'],
          sx: {
            '& button': {
              color: `${theme.palette.lunitTeal[40]} !important`,
              textTransform: 'capitalize',
              pointerEvents: canUseToday ? 'auto' : 'none',
              opacity: canUseToday ? 1 : 0.4,
            },
          },
        },
        day: {
          sx: {
            backgroundColor: 'transparent !important',
          },
        },
        ...(slotProps as any),
      }}
      {...(otherProps as any)}
    />
  );
}

export default Dialog;
