import { Box, styled } from '@mui/material';

const commonStyle = {
  display: 'flex' as const,
  flexDirection: 'column' as const,
  alignItems: 'center' as const,
  justifyContent: 'center' as const,
};
const FileDnDZoneContainer = styled(Box)(() => {
  return {
    height: '100%',
    width: '100%',
  };
});

const BaseFileDnDZone = styled(Box)(() => {
  return {
    ...commonStyle,
    gap: '16px',
    margin: '0 auto',
    paddingTop: '100px',
    maxWidth: '610px',
  };
});

const DnDSection = styled(Box)(() => {
  return {
    ...commonStyle,
    gap: '4px',
  };
});

const DnDMainBox = styled(Box)(() => {
  return {
    ...commonStyle,
    padding: '0 16px',
    gap: '24px',
  };
});

export { FileDnDZoneContainer, BaseFileDnDZone, DnDSection, DnDMainBox };
