import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';

const useSearchParams = () => {
  const location = useLocation();

  const result = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);

    return {
      searchParams,
      group: searchParams.get('group'),
      customerCode: searchParams.get('customer_code'),
      slide: searchParams.get('slide'),
    };
  }, [location]);

  return result;
};
export default useSearchParams;
