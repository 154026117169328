import { useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { Box } from '@mui/material';
import { Button } from '@lunit/oui';
import { PromptContext } from 'src/components/common/Prompt';
import { timerIsRunningAtom, timerStampsAtom, timerTimeAtom } from 'src/state/timer';
import { useMutation } from 'react-query';
import { saveReadingRecord } from 'src/api/slide/queries';
import type { ReadingRecord, SaveReadingRecordParams, SlideDetails } from 'src/api/slide/models';
import useSearchParams from 'src/hooks/useSearchParams';
import { commentAtom } from 'src/state/slides';
import { useMyInfoQuery } from 'src/hooks/useAuthQueries';
import useSnackbar from 'src/hooks/useSnackbar';
import { useReadingRecords } from 'src/hooks/useSlideQueries';

interface Params {
  currentReadingRecord?: ReadingRecord;
  selectedScore: string;
  currentSlide: SlideDetails;
}

type NewReadingRecordParams = Pick<
  SaveReadingRecordParams,
  'customer' | 'projectName' | 'slideName' | 'slideUrl'
>;

function newReadingRecord(params: NewReadingRecordParams): SaveReadingRecordParams {
  return {
    ...params,
    userId: '',
    score: '',
    startedAt: 0,
    endedAt: 0,
    duration: 0,
    comment: '',
  };
}

const useResearchMetrics = ({ selectedScore, currentSlide }: Params) => {
  const setRunning = useSetRecoilState(timerIsRunningAtom);
  const [stamps, setStamps] = useRecoilState(timerStampsAtom);
  const timerTime = useRecoilValue(timerTimeAtom);
  const commentData = useRecoilValue(commentAtom);

  const { handlePrompt } = useContext(PromptContext);
  const { enqueueSnackbar } = useSnackbar();

  const { group: projectName, customerCode, slide: slideName, searchParams } = useSearchParams();
  const { refetch } = useReadingRecords(searchParams, true);

  const { data: user } = useMyInfoQuery();

  const readingRecordMutation = useMutation(
    (payload: SaveReadingRecordParams) => saveReadingRecord(payload),
    {
      onSuccess: () => {
        refetch();
        handlePrompt(null);
      },
      onError: (e) => {
        enqueueSnackbar('Something went wrong. Please try again.', {
          variant: 'error',
        });
        console.error(e);
      },
    },
  );

  function handleSaveClick() {
    if (!projectName || !customerCode || !slideName || !currentSlide?.slideUrl) {
      enqueueSnackbar('Parameters are incomplete for saving reading record', {
        variant: 'error',
      });
      console.error('Parameters are incomplete for saving reading record:', {
        projectName,
        customerCode,
        slideName,
        slideUrl: currentSlide?.slideUrl,
      });
      return;
    }

    setRunning(false);
    const endTimestamp = Date.now();

    const readingRecordProperties = newReadingRecord({
      customer: customerCode,
      projectName,
      slideName,
      slideUrl: currentSlide.slideUrl,
    });

    const payload = {
      ...readingRecordProperties,
      userId: user?.userId || '',
      comment: commentData.comment,
      score: selectedScore,
      startedAt: stamps.start,
      endedAt: endTimestamp,
      duration: Math.floor(timerTime),
    };

    setStamps((prev) => ({ ...prev, end: endTimestamp }));

    handlePrompt({
      title: 'Save',
      content:
        'Do you want to save the data and close the case? After saving, it cannot be modified.',
      closable: true,
      useCustomAction: (
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
          <Button
            size="small"
            variant="ghost"
            label="Cancel"
            onClick={() => handlePrompt(null)}
          />
          <Button
            size="small"
            label="Confirm"
            loading={readingRecordMutation.isLoading}
            disabled={readingRecordMutation.isLoading}
            onClick={() => {
              readingRecordMutation.mutate(payload);
            }}
          />
        </Box>
      ),
    });
  }

  return {
    handleSaveClick,
    readingRecordMutation,
  };
};
export default useResearchMetrics;
