import { Chip, EllipsisTypography } from '@lunit/oncology-components';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import { useCallback, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { SlideDetails } from 'src/api/slide/models';
import env, { DEFAULT_API_TARGET } from 'src/env/index';
import { isVisCompleteAtom } from 'src/state';
import { slideInfoAtom } from 'src/state/slides';
import { ListChildComponentProps } from 'react-window';
import { LoadableImageContainer, SlideItemButton, SlideLinkContainer } from './styled';

function SlideLink({ index, style, data }: ListChildComponentProps) {
  const slide: SlideDetails = data[index];
  const isVisComplete = useRecoilValue(isVisCompleteAtom);
  const setSlideInfo = useSetRecoilState(slideInfoAtom);
  const [validStatus, setValidStatus] = useState('unknown'); // TODO define type 'unknown', 'invalid', 'valid'
  const handleError = useCallback(() => setValidStatus('invalid'), []);
  const handleLoad = useCallback(() => setValidStatus('valid'), []);
  const navigate = useNavigate();

  const slideFullPath = `${
    env.API_SLIDE_URLS[DEFAULT_API_TARGET]
  }/research/slides/thumbnail?file=${encodeURIComponent(
    slide.slideUrl,
  )}&width=60&height=98&img_type=jpeg`;
  const params = new URLSearchParams(window.location.search);
  const group = params.get('group');
  const customerCode = params.get('customer_code');
  const slideId = params.get('slide');
  const selectedId = slide.resultUrl;

  const handleSlideList = () => {
    setSlideInfo(slide.description);
    let link = `/visualizer?group=${group}&slide=${encodeURIComponent(slide.resultUrl)}`;
    if (customerCode) {
      link = `/visualizer?group=${group}&customer_code=${customerCode}&slide=${encodeURIComponent(
        slide.resultUrl,
      )}`;
    }
    navigate(link);
  };

  return (
    <ListItem
      style={style}
      sx={{
        height: 'auto',
        padding: (theme) => theme.spacing(0.5, 1),
        cursor: isVisComplete ? 'default' : 'not-allowed',
      }}
    >
      <ListItemButton
        onClick={handleSlideList}
        disabled={!isVisComplete}
        selected={selectedId === slideId}
        component={SlideItemButton}
        sx={{
          width: '100%',
          height: '100%',
          textDecoration: 'none',
        }}
      >
        <SlideLinkContainer>
          <Box
            sx={{
              lineHeight: 1,
              width: 60,
              height: '100%',
            }}
          >
            {validStatus === 'invalid' && (
              <LoadableImageContainer>
                <img
                  src="/empty-thumbnail.png"
                  alt=""
                  onError={handleError}
                />
              </LoadableImageContainer>
            )}
            {['unknown', 'valid'].includes(validStatus) && (
              <>
                {validStatus === 'unknown' && (
                  <Skeleton
                    height={98}
                    width={60}
                  />
                )}
                <LoadableImageContainer
                  style={{
                    display: validStatus === 'unknown' ? 'none' : 'block',
                  }}
                >
                  <img
                    src={slideFullPath}
                    alt=""
                    onError={handleError}
                    onLoad={handleLoad}
                  />
                </LoadableImageContainer>
              </>
            )}
          </Box>

          <Box
            sx={{
              height: 98,
              flex: 1,
              marginLeft: '12px',
              wordBreak: 'break-all',
            }}
          >
            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
              {slide.duration && (
                <Chip
                  preset="analyzed"
                  label="Done"
                  style={{ marginRight: '6px' }}
                />
              )}
              <EllipsisTypography>{`Case ${index + 1}`}</EllipsisTypography>
            </div>
            <EllipsisTypography
              sx={{
                fontSize: 12,
                lineHeight: '15px',
                fontWeight: 400,
                marginTop: '8px',
              }}
              direction="column"
              maxLines={4}
            >
              {validStatus === 'unknown' ? (
                <Skeleton
                  count={2}
                  width={136}
                  style={{ marginTop: '10px' }}
                />
              ) : (
                slide.description
              )}
            </EllipsisTypography>
          </Box>
        </SlideLinkContainer>
      </ListItemButton>
    </ListItem>
  );
}

export default SlideLink;
