import { createAPIQuery } from '@lunit/oncology-components/api/utils/apiQueryHelper';
import { FetchMethod, request } from '../utils/apiRequestHelper';
import type {
  ReadingRecord,
  SaveReadingRecordParams,
  SlidesApi,
} from './models';

const getSlides = createAPIQuery(
  (group: string, customerCode: string) =>
    request<SlidesApi>({
      method: FetchMethod.Get,
      path: `research/analysis/results?group=${group}&customer_code=${customerCode}`,
    }),
  () => ['slides', 'getSlides'],
);

const getSlideMsgpackPresignedUrl = createAPIQuery(
  (group: string, customerCode: string, msgpackPath: string) =>
    request<string>({
      method: FetchMethod.Get,
      path: `research/analysis/results/${group}/files/${encodeURIComponent(
        msgpackPath,
      )}?customer_code=${customerCode}`,
      getText: true,
    }),
  (msgpackPath: string) => ['BiomarkerMsgpackData', msgpackPath],
);

const getAllReadingRecords = createAPIQuery(
  (projectName: string, customerCode: string, slide?: string) => {
    const appendix = slide ? `&slideName=${slide}` : '';
    return request<ReadingRecord[]>({
      method: FetchMethod.Get,
      path: `reading-records?projectName=${encodeURIComponent(
        projectName,
      )}&customer=${customerCode}${appendix}`,
      urlBase: 'API_CARROT_URLS',
    });
  },
  (projectName: string, customerCode: string, slideName?: string) => [
    'all_reading_records',
    projectName,
    customerCode,
    slideName,
  ],
);

const saveReadingRecord = (payload: SaveReadingRecordParams) =>
  request({
    method: FetchMethod.Post,
    path: 'reading-records',
    payload,
    urlBase: 'API_CARROT_URLS',
  });

export {
  getSlides,
  getSlideMsgpackPresignedUrl,
  getAllReadingRecords,
  saveReadingRecord,
};
