import { Box, Typography } from '@mui/material';
import { PauseT1, PauseT2, Play } from '@lunit/oui/icons';
import type { ReadingRecord, SlideDetails } from 'src/api/slide/models';
import {
  FooterText,
  PauseButton,
  SaveButton,
  StartButton,
  Time,
  TimerContainer,
} from './ResearchTimer.styled';
import useResearchTimer from './useResearchTimer';
import useResearchMetrics from './useResearchMetrics';

interface Props {
  selectedScore: string;
  currentReadingRecord?: ReadingRecord;
  slideName: string | null;
  currentSlide: SlideDetails;
}

const ResearchTimer = ({ selectedScore, currentReadingRecord, slideName, currentSlide }: Props) => {
  const { duration } = currentReadingRecord ?? {
    duration: 0,
  };

  const {
    handleSaveClick,
    readingRecordMutation: { isLoading: isReadingRecordMutationLoading },
  } = useResearchMetrics({
    currentReadingRecord,
    selectedScore,
    currentSlide,
  });

  const {
    isTimerRunning,
    controls: { start, handlePauseResume },
    time: { formattedTime, raw },
  } = useResearchTimer(currentReadingRecord, slideName);

  const isPlayDisabled = isReadingRecordMutationLoading || !!duration || isTimerRunning || raw > 0;

  const isPauseDisabled =
    isReadingRecordMutationLoading || !!duration || (raw === 0 && !isTimerRunning);

  const isSaveDisabled =
    isReadingRecordMutationLoading || !selectedScore || !!duration || raw === 0;

  return (
    <TimerContainer>
      <Typography variant="body_b1">Timer</Typography>
      <Time variant="h7">{formattedTime}</Time>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <StartButton
          icon={<Play />}
          label="Start"
          onClick={start}
          disabled={isPlayDisabled}
        />
        <PauseButton
          icon={
            isTimerRunning || raw === 0 || currentReadingRecord?.score ? <PauseT2 /> : <PauseT1 />
          }
          label={isTimerRunning || raw === 0 || currentReadingRecord?.score ? 'Pause' : 'Resume'}
          onClick={handlePauseResume}
          disabled={isPauseDisabled}
        />
      </Box>
      <SaveButton
        label="Save"
        disabled={isSaveDisabled}
        onClick={handleSaveClick}
      />
      <FooterText
        variant="body_m2"
        disabled={isSaveDisabled}
      >
        Save the data before you leave the page. The unsaved data will not be restorable.
      </FooterText>
    </TimerContainer>
  );
};
export default ResearchTimer;
