import { theme } from '@lunit/oui';
import { Button } from '@lunit/oui/Button';
import { Typography, TypographyProps, styled } from '@mui/material';

export const TimerContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  gap: '16px',
  width: '243px',
  padding: '16px 0',
});

export const StartButton = styled(Button)({
  backgroundColor: theme.palette.green[30],
  width: '112px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.green[30],
    opacity: '0.4',
  },
  '&:hover': {
    backgroundColor: theme.palette.green[30],
  },
});
export const PauseButton = styled(Button)({
  backgroundColor: theme.palette.yellow[5],
  width: '112px',
  '&.Mui-disabled': {
    backgroundColor: theme.palette.yellow[5],
    opacity: '0.4',
  },
  '&:hover': {
    backgroundColor: theme.palette.yellow[5],
  },
});
export const SaveButton = styled(Button)({
  backgroundColor: '#768EF2',
  width: '100%',
  '&.Mui-disabled': {
    backgroundColor: '#768EF2',
    opacity: '0.4',
  },
  '&:hover': {
    backgroundColor: '#768EF2',
  },
});

export const FooterText = styled(Typography, { shouldForwardProp: (prop) => prop !== 'disabled' })<
  TypographyProps & { disabled: boolean }
>(({ disabled }) => ({
  color: theme.palette.neutralGrey[40],
  opacity: disabled ? '0.4' : '1',
}));

export const Time = styled(Typography)({
  background: '#3E3E40',
  borderRadius: '8px',
  height: '44px',
  padding: '4px 16px',
  width: '100%',
});
