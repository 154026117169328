import { useCallback, useContext, useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { LoadingIndicatorDeprecated } from '@lunit/oncology-components';
import { ReadingRecord, SlidesApi } from 'src/api/slide/models';
import { useLocation } from 'react-router-dom';
import { getSlideIndexFromList } from 'src/utils/projects';
import { isVisCompleteAtom } from 'src/state';
import useSnackbar from 'src/hooks/useSnackbar';
import useResultData from 'src/hooks/visualizer/useResultData';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { PromptContext } from 'src/components/common/Prompt';
import { Box, Typography } from '@mui/material';
import useSearchParams from 'src/hooks/useSearchParams';
import useCurrentReadingRecord from 'src/hooks/useCurrentReadingRecord';
import SlideViewer from './component/SlideViewer';
import SlideViewerContainer from './component/SlideViewer/SlideViewerContainer';

import ViewOptions from './component/ViewOptions';
import { currentReadingRecordAtom } from 'src/state/readingRecords';

const dummyToolState = {
  activeToolType: 0,
  activeToolId: 'hand',
  tempHandEnabled: false,
  toolsEnabled: false,
};

function Visualizer({ results: slides, labels }: SlidesApi) {
  const [enabledClasses, setEnabledClasses] = useState<string[]>([]);
  const [currentSlide, setCurrentSlide] = useState<any>();
  const [histologyActive, setHistologyActive] = useState<boolean>(true);
  const [isVisComplete, setIsVisComplete] = useRecoilState<boolean>(isVisCompleteAtom);
  const setCurrentReadingRecord = useSetRecoilState<ReadingRecord | undefined>(currentReadingRecordAtom);
  const { handlePrompt } = useContext(PromptContext);

  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();

  const { customerCode, group, slide: slideName } = useSearchParams();

  const { isLoading, isError, slideData, taskClasses } = useResultData(
    currentSlide?.resultUrl,
    new URLSearchParams(location.search),
    labels,
  );

  const { currentReadingRecord } = useCurrentReadingRecord({
    groupId: group,
    slideName,
    customerCode,
  });

  setCurrentReadingRecord(currentReadingRecord);

  const handleStartTimerPrompt = useCallback(() => {
    // 'dummyRecordData' should be replaced with the actual data from the API @sh.bae
    if (!currentReadingRecord?.duration)
      handlePrompt({
        title: 'Start the Timer',
        closable: true,
        icon: <AccessAlarmIcon />,
        submitText: 'Confirm',
        content: (
          <Box width={280}>
            <Typography variant="body5">
              Press the start button of the timer on the right panel to record your reading.
            </Typography>
          </Box>
        ),
      });
  }, [currentReadingRecord, handlePrompt]);

  // Handle navigating to selected slide
  useEffect(() => {
    if (slides.length !== 0) {
      if (slideName && slideName !== 'undefined') {
        const index = getSlideIndexFromList(slideName, slides) - 1;

        if (index > -1) {
          const thisSlide = slides[index];
          if (!currentSlide || currentSlide?.resultUrl !== thisSlide.resultUrl) {
            setCurrentSlide(thisSlide);
          }
        } else {
          // eslint-disable-next-line no-alert
          alert('Invalid slide ID');
          const previousSlideId = currentSlide ? currentSlide.resultUrl : slides[0]?.resultUrl;
          // force viewer to reset by deleting currentSlide
          setCurrentSlide(null);
          let link = `/visualizer?group=${group}&slide=${previousSlideId}`;
          if (customerCode) {
            link = `/visualizer?group=${group}&customer_code=${customerCode}&slide=${previousSlideId}`;
          }
          window.location.replace(link);
        }
      } else {
        let link = `/visualizer?group=${group}&slide=${encodeURIComponent(slides[0]?.resultUrl)}`;
        if (customerCode) {
          link = `/visualizer?group=${group}&customer_code=${customerCode}&slide=${encodeURIComponent(
            slides[0]?.resultUrl,
          )}`;
        }
        window.location.replace(link);
      }
    }
    return () => {
      setIsVisComplete(false);
      setEnabledClasses([]);
    };
  }, [slides, currentSlide, location.search, setIsVisComplete, group, customerCode, slideName]);

  useEffect(() => {
    if (currentSlide) {
      const defaultOnClasses = labels.filter((label) => label.isChecked).map((label) => label.id);
      if (defaultOnClasses.length > 0) setEnabledClasses(defaultOnClasses);
    }
  }, [currentSlide, labels]);

  // Handle error
  useEffect(() => {
    if (isError) {
      enqueueSnackbar(
        'Analysis result not found',
        {
          variant: 'error',
          preventDuplicate: true,
        },
        { isLight: true },
      );
    }
  }, [isError, enqueueSnackbar]);

  return (
    <>
      <SlideViewerContainer
        toolState={dummyToolState}
        slideViewport={
          !isLoading &&
          currentSlide &&
          slideData?.currentSlide &&
          currentSlide?.slideUrl && (
            <SlideViewer
              slideData={slideData}
              taskClasses={taskClasses}
              currentSlide={currentSlide}
              isVisComplete={isVisComplete}
              enabledClasses={enabledClasses}
              histologyActive={histologyActive}
              handleStartTimerPrompt={handleStartTimerPrompt}
              handleVisComplete={(state: boolean) => setIsVisComplete(state)}
            />
          )
        }
        sidePanel={
          currentSlide && (
            <ViewOptions
              isError={isError}
              active={histologyActive}
              currentReadingRecord={currentReadingRecord}
              currentSlide={currentSlide}
              slideName={slideName}
              data={slideData?.analysisData}
              enabledClasses={enabledClasses}
              cellClasses={slideData?.cellClasses}
              taskClasses={taskClasses}
              tissueClasses={slideData?.tissueClasses}
              structureClasses={slideData?.structureClasses}
              handleActive={(active: boolean) => setHistologyActive(active)}
              handleClass={(values: string[]) => setEnabledClasses(values)}
            />
          )
        }
      />
      {!isError && (isLoading || (currentSlide && !slideData?.currentSlide) || !isVisComplete) && (
        <LoadingIndicatorDeprecated
          message={isLoading || !slideData?.currentSlide ? 'Fetching Data..' : ''}
        />
      )}
    </>
  );
}

export default Visualizer;
