import { atom } from 'recoil';
import { ReadingRecord } from 'src/api/slide/models';

const readingRecordsAtom = atom<{ data: ReadingRecord[] }>({
  key: 'readingRecords',
  default: {
    data: [],
  },
});

const currentReadingRecordAtom = atom<ReadingRecord | undefined>({
  key: 'currentReadingRecord',
  default: undefined,
});

export { readingRecordsAtom, currentReadingRecordAtom };
