import { useCallback, useState } from 'react';
import { PromptContent } from './Prompt.types';

const usePrompt = () => {
  const [open, setOpen] = useState(false);
  const [promptContent, setPromptContent] = useState<PromptContent | null>(null);

  const handlePrompt = useCallback((content: PromptContent | null) => {
    if (content) {
      setPromptContent(content);
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, []);

  return { open, handlePrompt, promptContent };
};

export default usePrompt;
