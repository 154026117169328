import { CloseSmall } from '@lunit/oncology-icons';

import { Box } from '@mui/material';
import theme from '../../theme';
import { Button } from '../Button';

import {
  StyledDialog,
  StyledHeaderBox,
  StyledDialogTitle,
  StyledDialogContent,
  StyledDialogActions,
} from './Dialog.styled';
import type { DialogProps } from './Dialog.types';

function Dialog({
  open,
  title,
  icon,
  closable,
  isPrompt,
  cancelText,
  submitText,
  onClose,
  text,
  children,
  dialogAction,
  buttonSize = 'small',
  loading = false,
  disableSubmit = false,
  sx,
}: DialogProps) {
  const handleClose = (event: object | null, reason: string) => {
    if (onClose) {
      onClose(event, reason);
    }
  };
  const displayChildren = !text && !!children;

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      displayChildren={displayChildren}
      isPrompt={isPrompt}
      sx={sx}
      className="customDialog"
      PaperProps={{
        className: 'dialogPaper',
      }}
    >
      <StyledHeaderBox
        isPrompt={isPrompt}
        displayChildren={displayChildren}
        className="dialogTitle"
      >
        <Box>
          <StyledDialogTitle>
            {icon && <div className="dialogIcon">{icon}</div>}
            {title}
          </StyledDialogTitle>
        </Box>
        {closable && (
          <Button
            size="small"
            variant="ghost"
            label=""
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onClick={(event: any) => handleClose(event, 'cancel')}
            icon={<CloseSmall />}
            sx={{ color: theme.palette.neutralGrey[0] }}
          />
        )}
      </StyledHeaderBox>

      {!displayChildren && <StyledDialogContent>{text}</StyledDialogContent>}
      {displayChildren && (
        <StyledDialogContent className="childrenDialog">{children}</StyledDialogContent>
      )}

      {dialogAction && <StyledDialogActions>{dialogAction}</StyledDialogActions>}

      {!dialogAction && (cancelText || submitText) && (
        <StyledDialogActions>
          {cancelText && (
            <Button
              label={cancelText}
              size={buttonSize}
              variant="ghost"
              onClick={() => handleClose(null, 'cancel')}
            />
          )}

          {submitText && (
            <Button
              disabled={disableSubmit}
              loading={loading}
              label={submitText}
              size={buttonSize}
              sx={loading ? { width: '75px' } : null}
              onClick={() => handleClose(null, 'submit')}
            />
          )}
        </StyledDialogActions>
      )}
    </StyledDialog>
  );
}

export default Dialog;
