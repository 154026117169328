import { Box, Typography } from '@mui/material';
import { forwardRef } from 'react';
import BaseTooltip from './Tooltip.styled';
import type { TooltipProps } from './Tooltip.types';

const Tooltip = forwardRef<HTMLDivElement, TooltipProps>(
  (
    {
      icon,
      title,
      children,
      size = 'small',
      placement = 'bottom',
      noPadding = false,
      ...otherProps
    }: TooltipProps,
    ref,
  ) => {
    return (
      <BaseTooltip
        ref={ref}
        arrow
        size={size}
        PopperProps={{
          popperOptions: {
            modifiers: [
              {
                name: 'offset',
                enabled: true,
                options: {
                  offset: [0, 6],
                },
              },
            ],
          },
        }}
        title={
          icon && size === 'small' ? (
            <Box sx={{ display: 'flex', flexDirection: 'row', gap: '8px' }}>
              {icon}
              {title && <Typography variant="body5">{title}</Typography>}
            </Box>
          ) : (
            title
          )
        }
        placement={placement}
        noPadding={noPadding}
        {...otherProps}
      >
        {children}
      </BaseTooltip>
    );
  },
);

export default Tooltip;
