import { atom, selector } from 'recoil';

export const timerIsRunningAtom = atom<boolean>({
  key: 'timerIsRunning',
  default: false,
});

export const timerTimeAtom = atom<number>({
  key: 'timerTime',
  default: 0,
});
export const timerStampsAtom = atom<{ start: number; end: number }>({
  key: 'timerStamps',
  default: { start: 0, end: 0 },
});

export const timerCommentScoreDisabledAtom = selector({
  key: 'timerIsCommentAndScoreDisabled',
  get: ({ get }) => {
    const timerIsRunning = get(timerIsRunningAtom);
    const timerTime = get(timerTimeAtom);
  
    return !timerIsRunning ? timerTime === 0 : false;

  }
})
