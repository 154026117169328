import { useEffect, useState } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import type { ReadingRecord } from 'src/api/slide/models';
import { timerIsRunningAtom, timerStampsAtom, timerTimeAtom } from 'src/state/timer';

// Tobio: slideName is passed ONLY to make the timer reset when changing between slides
// even location.pathname doesn't work.
const useResearchTimer = (
  currentReadingRecord: ReadingRecord | undefined,
  slideName: string | null,
) => {
  const [isRunning, setRunning] = useRecoilState(timerIsRunningAtom);
  const [time, setTime] = useRecoilState(timerTimeAtom);
  const [startTime, setStartTime] = useState<number | null>(null);
  const setTimeStamps = useSetRecoilState(timerStampsAtom);

  // Initialize timer as paused on mount
  useEffect(() => {
    setRunning(false);
    if (currentReadingRecord?.duration) {
      setTime(currentReadingRecord.duration);
    } else {
      setTime(0);
    }
    setStartTime(null);
  }, [setRunning, setTime, setStartTime, currentReadingRecord, slideName]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isRunning && startTime !== null) {
      intervalId = setInterval(() => {
        const now = new Date().getTime();
        setTime((now - startTime) / 1000);
      }, 100);
    }
    return () => clearInterval(intervalId);
  }, [isRunning, setTime, startTime]);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = Math.floor(time % 60);

  function zeroPad(num: number) {
    return num.toString().padStart(2, '0');
  }

  function startTimer() {
    setTimeStamps((prev) => ({ ...prev, start: new Date().getTime() }));
    setStartTime(new Date().getTime());
    setRunning(true);
  }

  function pauseTimer() {
    setRunning(false);
    setStartTime(new Date().getTime());
  }

  function resumeTimer() {
    const now = new Date().getTime();
    setStartTime(now - time * 1000);
    setRunning(true);
  }

  return {
    isTimerRunning: isRunning,
    controls: {
      start: () => startTimer(),
      pause: () => pauseTimer(),
      resume: () => resumeTimer(),
      reset: () => {
        setStartTime(null);
        setTime(0);
      },
      handlePauseResume: () => {
        if (isRunning) pauseTimer();
        else resumeTimer();
      },
    },
    time: {
      raw: time,
      hours,
      minutes,
      seconds,
      formattedTime: `${zeroPad(hours)}:${zeroPad(minutes)}:${zeroPad(seconds)}`,
    },
  };
};
export default useResearchTimer;
