import { Box, FormControl, FormControlLabel, FormLabel, Typography } from '@mui/material';
import { Radio, RadioGroup } from '@lunit/oncology-components';

interface ScoreProps {
  selectedScore: string;
  recordScore: string;
  onChangeScoreHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}

const scores: string[] = ['0', 'Ultra-low', '1+', '2+', '3+'];

const ScoreFormControlLabel = ({
  score,
  isDisabled,
  indent = false,
}: {
  score: string;
  isDisabled?: boolean;
  indent?: boolean;
}) => (
  <FormControlLabel
    value={score}
    label={score}
    disabled={isDisabled}
    control={<Radio sx={{ marginRight: '8px' }} />}
    sx={{ marginLeft: indent ? '16px' : '0px' }}
    componentsProps={{
      typography: { fontWeight: 400, fontSize: '14px', lineHeight: '20px' },
    }}
  />
);

const Score = ({ selectedScore, recordScore, onChangeScoreHandler, disabled }: ScoreProps) => {
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '16px 0',
        }}
      >
        <Typography variant="body_b1">Score</Typography>
      </Box>
      <FormControl
        sx={{
          marginLeft: '-16px',
          backgroundColor: '#232323',
          width: 'calc(100% + 32px)',
        }}
      >
        <RadioGroup
          sx={{ gap: '14px', padding: '16px' }}
          value={recordScore || selectedScore}
          onChange={onChangeScoreHandler}
        >
          {scores.map((score) => (
            <ScoreFormControlLabel
              key={score}
              score={score}
              isDisabled={disabled}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default Score;
