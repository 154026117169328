import { Box, useTheme } from '@mui/material';
import type { ReadingRecord, SlideDetails } from 'src/api/slide/models';
import Analysis from './Analysis';
import PanelSelector from './PanelSelector';
import { AnalysisData, TaskClasses } from '../../types';

interface ViewOptionsProps {
  isError: boolean;
  active: boolean;
  cellClasses: [];
  data: AnalysisData[];
  taskClasses: TaskClasses;
  tissueClasses: [];
  enabledClasses: string[];
  structureClasses: [];
  currentReadingRecord?: ReadingRecord;
  slideName: string | null;
  currentSlide: SlideDetails;
  handleClass: (values: string[]) => void;
  handleActive: (active: boolean) => void;
}

function ViewOptions({
  isError,
  active,
  cellClasses,
  data,
  taskClasses,
  tissueClasses,
  enabledClasses,
  structureClasses,
  currentReadingRecord,
  currentSlide,
  slideName,
  handleClass,
  handleActive,
}: ViewOptionsProps) {
  const theme = useTheme();
  return (
    <Box
      id="viewOptions"
      sx={{
        width: '340px',
        overflow: 'auto',
        backgroundColor: theme.palette.background.secondary,
      }}
    >
      <PanelSelector
        isError={isError}
        classes={enabledClasses}
        active={active}
        cellClasses={cellClasses}
        taskClasses={taskClasses}
        tissueClasses={tissueClasses}
        currentReadingRecord={currentReadingRecord}
        currentSlide={currentSlide}
        slideName={slideName}
        structureClasses={structureClasses}
        onChange={handleClass}
        onChangeActive={handleActive}
      />
      {data && data.length > 0 && (
        <Analysis
          data={data}
          taskClasses={taskClasses}
        />
      )}
    </Box>
  );
}

export default ViewOptions;
