import { BaseSyntheticEvent, useEffect, useRef, useState } from 'react';

import { Box, Typography, Collapse } from '@mui/material';
import { TextArea } from '@lunit/oncology-components';
import { ArrowDownSm, ArrowUpSm } from '@lunit/oncology-icons';
import { useRecoilState, useRecoilValue } from 'recoil';
import { commentAtom, slideInfoAtom } from 'src/state/slides';
import { CommentWrapper } from './styled';
import { currentReadingRecordAtom } from 'src/state/readingRecords';

interface CommentProps {
  isLoading: boolean;
  recordScore: string;
  recordComment: string;
  disabled?: boolean;
}

const Comment = ({ isLoading, recordScore, recordComment, disabled }: CommentProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const slideInfo = useRecoilValue(slideInfoAtom);
  const [commentData, setCommentData] = useRecoilState(commentAtom);
  const currentReadingRecord = useRecoilValue(currentReadingRecordAtom);
  const prevSlideInfoRef = useRef(slideInfo);
  const [comment, setComment] = useState<string>('');
  const onClickArrow = () => {
    setIsOpened(!isOpened);
  };

  const onChangeComment = (event: BaseSyntheticEvent) => {
    setComment(event.target.value);
    setCommentData({ comment: event.target.value });
  };

  useEffect(() => {
    if (currentReadingRecord?.comment !== commentData.comment) {
      setComment(currentReadingRecord?.comment || commentData.comment);
    }
    if (slideInfo !== prevSlideInfoRef.current) {
      setIsOpened(false);
      setComment(currentReadingRecord?.comment!);
      setCommentData({ comment: currentReadingRecord?.comment ?? '' });
      prevSlideInfoRef.current = slideInfo;
    }
  }, [recordComment, commentData, currentReadingRecord, slideInfo]);

  return (
    <CommentWrapper>
      <Box
        sx={{
          display: 'flex',
          height: '36px',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Typography variant="body_b1">Comment</Typography>
        <Box
          sx={{ cursor: 'pointer' }}
          onClick={onClickArrow}
        >
          {isOpened ? <ArrowUpSm /> : <ArrowDownSm />}
        </Box>
      </Box>
      <Collapse
        in={isOpened}
        timeout="auto"
        unmountOnExit
      >
        <TextArea
          height="240px"
          placeholder={!recordScore ? 'Please leave a comment for the analysis.' : ''}
          readOnly={isLoading || disabled}
          value={comment}
          onChange={onChangeComment}
        />
      </Collapse>
    </CommentWrapper>
  );
};

export default Comment;
