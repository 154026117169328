import { Box, styled } from '@mui/material';
import reduce from 'lodash/reduce';
import theme from '../theme';

type CheckBoxProps = {
  color?: string;
  halfChecked?: boolean;
  invisible?: boolean;
};

type ControlWrapperProps = {
  indented?: boolean;
  checked?: boolean;
  invisible?: boolean;
};

export const Wrapper = styled(Box)`
  width: 100%;
  padding: 0 16px;
  user-select: none;
`;

export const ControlWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'invisible',
})`
  position: relative;
  height: 48px;
  margin-left: ${(props: ControlWrapperProps) => (props.indented ? '30px' : 0)};
  padding: 14px 4px;
  input {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
  label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
  span {
    flex: 1;
    color: ${(props: ControlWrapperProps) => (!props.checked ? theme.lightText : theme.lightText)};
    font-size: 14px;
    ${(props: ControlWrapperProps) => (props.invisible ? 'opacity: 0.5;' : '')}
  }
`;

export const Checkbox = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'halfChecked' && prop !== 'invisible',
})`
  position: relative;
  width: 12px;
  height: 12px;
  margin: 2px 10px 1px 2px;
  border: 2px solid ${(props: CheckBoxProps) => props.color};
  border-radius: 2px;
  background-color: ${(props: CheckBoxProps) => (props.halfChecked ? props.color : 'transparent')};
  transition: 0.25s ease-out;
  /* ${(props: CheckBoxProps) => (props.invisible ? 'opacity: 0.5;' : '')} */
  input:checked ~ & {
    background-color: ${(props) => props.color};
  }
  img {
    position: absolute;
    top: -2px;
    left: -2px;
    width: 12px;
    height: 12px;
  }
`;

export const ToggleWrapper = styled(Box)`
  height: 47px;
  padding: 15px 0;
  border: 1px solid ${theme.darkBg};
  border-left: 0;
  border-right: 0;
  input {
    float: left;
    margin: 0;
    width: 0;
    height: 0;
    max-height: 0;
    max-width: 0;
    opacity: 0;
  }
`;

export const Toggle = styled(Box)`
  display: inline-block;
  position: relative;
  width: 26px;
  height: 14px;
  margin-right: 18px;
  border-radius: 7px;
  background-color: ${theme.disabled};
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 7px;
    background-color: ${theme.disabled};
    transition: 0.25s ease-out;
  }
  &:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: white;
    transition: 0.25s ease-out;
  }
`;

export const Label = styled('label')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 16px;
  line-height: 16px;
  font-size: 14px;
  color: ${theme.lightText};
  cursor: pointer;
  transition: 0.25s ease-out;
  span {
    flex: 1;
    line-height: 38px - 4px;
  }
  input:checked + & div {
    &:before {
      width: 26px;
      background-color: ${theme.primary};
    }
    &:after {
      left: 14px;
    }
  }
`;

export const PieDD = styled('dd')`
  flex-basis: 100% !important;
  & > div {
    width: 50%;
    margin: 14px 0 10px 25%;
  }
  dt {
    text-align: left;
  }
  & > dl > dd {
    margin-bottom: 2px;
  }
`;

export const SectionWrapper = styled(Box)`
  border-top: 1px solid ${theme.darkBg};
  h3 {
    margin: 14px 0;
    font-size: 14px;
    font-weight: 500;
    color: ${theme.lightText};
  }
  dl,
  dt,
  dd {
    margin: 0;
    font-size: 14px;
  }
  dl {
    display: flex;
    flex-wrap: wrap;
  }
  & > dl {
    padding: 0;
  }
  dt {
    flex: 1 1 55%;
    margin-bottom: 5px;
    color: ${theme.primaryText};
    word-break: break-all;
  }
  dd {
    flex: 1 1 45%;
    margin-bottom: 16px;
    text-align: right;
    font-weight: 400;
    color: ${theme.lightText};
  }
`;

export const Bar = styled(Box)`
  position: relative;
  width: 218px;
  height: 6px;
  margin-top: 5px;
  border-radius: 3px;
  background-color: ${theme.darkBg};
  &:before {
    position: absolute;
    display: block;
    width: ${(props: { value: number }) => props.value * 218}px;
    height: 6px;
    border-radius: 3px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    background-color: ${theme.primary};
    content: '';
  }
`;

export const Indicator = styled('div')`
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 9px;
  border-radius: 1px;
  background-color: ${(props) => props.color};
`;

export const ColorMap = styled('div')({
  flex: 1,
  height: 4,
  borderRadius: 2,
});

export const STOP_WIDTH = 54;
export const ColorMapStop = styled('span')({
  position: 'absolute',
  top: 10,
  opacity: 0.9,
  width: STOP_WIDTH,
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '8px',
  lineHeight: '12px',
});

export const getGradientFromColors = (colors: string[]): string =>
  `${reduce(
    colors,
    (gradient, color, index) =>
      `${gradient}, ${color} ${(index / colors.length) * 100}% ${
        index + (1 / colors.length) * 100
      }%`,
    'linear-gradient(to right',
  )})`;

export const SectionListItem = styled('div')({
  width: '100%',
  height: 32,
  flex: '0 0 32px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingLeft: '20px',
  paddingRight: '20px',
});

export const CommentWrapper = styled(Box)`
  border-bottom: 1px solid ${theme.darkBg};
  padding: 16px 0;
`;

export const TimerWrapper = styled(Box)`
  border-bottom: 1px solid ${theme.darkBg};
  padding: 15px 0;
`;

export const ScoreOptions = styled(Box)`
  background-color: #232323;
  padding: 16px;
  margin: 0 -16px;
`;
